<template>
    <prime-button
        icon="pi pi-trash"
        text
        icon-pos="right"
        size="small"
        severity="danger"
        :loading="cartDeletePending || checkoutDeletePending"
        @click="confirmDelete"
    />
</template>

<script setup lang="ts">
import { useMutation, useQueryClient } from '@tanstack/vue-query'
import { useRpQuery } from '~/composables/graphql'
import { graphql } from '~/resources/graphql'
import { useConfirm } from 'primevue/useconfirm'
import PrimeButton from 'primevue/button'
import {
    setLastDeletedContractId,
    useSetCartData
} from '~/composables/use-cart'
import type {
    DeleteCheckoutContractInput,
    DeleteContractInput
} from '~/resources/graphql/graphql'
import { useActiveFacility, useRouteLink } from '#imports'
import { useRoute } from '#app'
import { useRouter } from 'vue-router'

const props = defineProps({
    contractId: {
        type: String,
        required: true
    }
})

const emit = defineEmits({
    deleted: () => {
        return true
    }
})

const query = useRpQuery()
const activeFacility = useActiveFacility()
const setCartData = useSetCartData()
const { mutate: deleteCartContract, isPending: cartDeletePending } =
    useMutation({
        mutationFn: async (input: DeleteContractInput) => {
            const response = await query(
                graphql(/** GraphQL */ `
                    mutation DeleteContract(
                        $input: DeleteContractInput!
                        $facilityId: ID!
                    ) {
                        deleteContract(input: $input) {
                            __typename
                            ... on DeleteContractResult {
                                recordId
                                query {
                                    viewer {
                                        cart(facilityId: $facilityId) {
                                            ...ViewerCartFragment
                                        }
                                    }
                                }
                            }
                        }
                    }
                `),
                {
                    input,
                    facilityId: activeFacility.value.id
                }
            )

            if (response.deleteContract.__typename === 'DeleteContractResult') {
                return response.deleteContract
            } else {
                throw new Error('Error deleting contract.')
            }
        }
    })

const { mutate: deleteCheckoutContract, isPending: checkoutDeletePending } =
    useMutation({
        mutationFn: async (input: DeleteCheckoutContractInput) => {
            const response = await query(
                graphql(/** GraphQL */ `
                    mutation DeleteCheckoutContract(
                        $input: DeleteCheckoutContractInput!
                        $facilityId: ID!
                    ) {
                        deleteCheckoutContract(input: $input) {
                            __typename
                            ... on DeleteCheckoutContractResult {
                                recordId
                                query {
                                    viewer {
                                        cart(facilityId: $facilityId) {
                                            ...ViewerCartFragment
                                        }
                                    }
                                }
                            }
                            ... on EmptyCheckoutResult {
                                query {
                                    viewer {
                                        cart(facilityId: $facilityId) {
                                            ...ViewerCartFragment
                                        }
                                    }
                                }
                            }
                        }
                    }
                `),
                {
                    input,
                    facilityId: activeFacility.value.id
                }
            )

            if (response.deleteCheckoutContract === null) {
                throw new Error(
                    'Error deleting contract. Please refresh and try again.'
                )
            }

            if (
                response.deleteCheckoutContract.__typename ===
                    'DeleteCheckoutContractResult' ||
                response.deleteCheckoutContract.__typename ===
                    'EmptyCheckoutResult'
            ) {
                return response.deleteCheckoutContract
            }

            throw new Error('Error deleting contract.')
        }
    })

const confirm = useConfirm()
const route = useRoute()
const queryClient = useQueryClient()
const router = useRouter()
const { facilityHomeLink } = useRouteLink()
function confirmDelete() {
    if (route.path.includes('/checkout/')) {
        const checkoutId = route.params.id
        if (Array.isArray(checkoutId)) {
            throw new Error()
        }
        confirm.require({
            group: 'deleteCheckoutItem',
            message: 'Do you want to delete this item?',
            header: 'Confirm Delete',
            acceptClass:
                '!text-white bg-red-500 border border-red-500 hover:bg-red-600 hover:border-red-600',
            accept: () => {
                deleteCheckoutContract(
                    { contractId: props.contractId, checkoutId: checkoutId },
                    {
                        onSuccess: data => {
                            if (data.__typename === 'EmptyCheckoutResult') {
                                router.push(
                                    facilityHomeLink(activeFacility.value.slug)
                                )
                            } else if (
                                data.__typename ===
                                'DeleteCheckoutContractResult'
                            ) {
                                queryClient.invalidateQueries({
                                    predicate: query =>
                                        query.queryKey.includes(
                                            'CheckoutViewerQuery'
                                        )
                                })
                            } else {
                                throw new Error()
                            }
                            emit('deleted')
                        }
                    }
                )
            }
        })
        return
    }

    confirm.require({
        group: 'deleteCartItem',
        message: 'Do you want to delete this item?',
        header: 'Confirm Delete',
        acceptClass:
            '!text-white bg-red-500 border border-red-500 hover:bg-red-600 hover:border-red-600',
        accept: () => {
            deleteCartContract(
                { id: props.contractId },
                {
                    onSuccess: data => {
                        // Update the cart data
                        if (data.query.viewer !== null) {
                            setCartData(data.query.viewer.cart)
                        }
                        setLastDeletedContractId(props.contractId)
                        queryClient.invalidateQueries({
                            predicate: query =>
                                query.queryKey.includes('CourseCalendarQuery')
                        })
                        emit('deleted')
                    }
                }
            )
        }
    })
}
</script>
