<template>
    <plan-contract-cart-item
        v-if="itemData.contract.__typename === 'PlanContract'"
        :contract="itemData.contract"
        :total="subtotal"
        :non-discounted-subtotal="nonDiscountedSubtotal"
    />

    <prime-message
        v-else
        severity="error"
        :closable="false"
    >
        Oops! Unable to view custom contracts at this time.
    </prime-message>
</template>

<script lang="ts">
import { graphql } from '~/resources/graphql'

export const CartSidebarContractCartItemFragment = graphql(/* GraphQL */ `
    fragment CartSidebarContractCartItemFragment on CartContractItem {
        contract {
            __typename
            ... on PlanContract {
                ...PlanContractCartItemFragment
            }
        }
        subtotal
        nonDiscountedSubtotal
    }
`)
</script>

<script setup lang="ts">
import PrimeMessage from 'primevue/message'
import { Decimal } from '~/composables/support/decimal'
import type { FragmentType } from '~/resources/graphql'
import { getFragmentData } from '~/resources/graphql'
import { computed } from 'vue'
import PlanContractCartItem from '~/components/CartSidebar/PlanContractCartItem.vue'

const props = defineProps<{
    item: FragmentType<typeof CartSidebarContractCartItemFragment>
}>()

const itemData = computed(() =>
    getFragmentData(CartSidebarContractCartItemFragment, props.item)
)

const subtotal = computed(() => new Decimal(itemData.value.subtotal))
const nonDiscountedSubtotal = computed(
    () => new Decimal(itemData.value.nonDiscountedSubtotal)
)
</script>
